import React from "react";
import close from "../images/icons/x.svg";
import Modal from "react-modal";
import Link from "next/link";
import FullLogo from "../images/Logos/logo.svg";
import config from "../data/config";
import classNames from "classnames";

const customStyles = {
  content: {
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",
  },
};

export default function MobileMenu({
  modalIsOpen,
  closeModal,
  openModal,
  menu,
}) {
  const modalLinkClassName =
    "cursor-pointer hover:underline text-2xl mx-2 lg:mx-5 leading-loose";
  return (
    <>
      <img
        src={menu}
        className="w-8 cursor-pointer m-3 md:hidden"
        onClick={openModal}
        alt="menu icon"
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        className="bg-white z-50"
      >
        <div
          className="flex flex-col items-center h-screen bg-white z-50"
          onClick={closeModal}
        >
          <img
            src={close}
            onClick={closeModal}
            className="w-8 self-end m-5 cursor-pointer"
            alt="close icon"
          />
          {config.ENABLE_REGISTRATION && (
            <Link href="/register">
              <a className={modalLinkClassName}>Register</a>
            </Link>
          )}
          <Link href="/#emcees">
            <a className={modalLinkClassName}>Emcees</a>
          </Link>
          <Link href="/#speakers">
            <a className={modalLinkClassName}>Speakers</a>
          </Link>
          <Link href="/#schedule">
            <a className={modalLinkClassName}>Schedule</a>
          </Link>
          <Link href="/#sponsors">
            <a className={modalLinkClassName}>Sponsors</a>
          </Link>
          <Link href="/jobs">
            <a className={modalLinkClassName}>Jobs</a>
          </Link>
          <Link href="/#organizers">
            <a className={modalLinkClassName}>Organizers</a>
          </Link>
          <Link href="/">
            <img className="h-16 mt-32" src={FullLogo} alt="logo" />
          </Link>
        </div>
      </Modal>
    </>
  );
}
