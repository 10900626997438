import React from "react";
import Link from "next/link";
import FullLogo from "../images/Logos/logo.svg";
import MobileMenu from "./MobileMenu";
import menu from "../images/icons/menu_white.svg";
import config from "../data/config";
import classNames from "classnames";

export default function FixedNavbar({ openModal, closeModal, modalIsOpen }) {
  const linkClassName =
    "cursor-pointer hover:underline text-xl lg:text-2xl mx-2 lg:mx-5";
  return (
    <div className="w-full fixed bg-white z-10 border-b border-blue z-50">
      <div className="flex h-16 md:h-24">
        <div className="flex w-full md:w-auto justify-between items-center">
          <Link href="/">
            <a className={linkClassName}>
              <img className="h-12 m-5" src={FullLogo} alt="logo" />
            </a>
          </Link>
          <div className="bg-blue h-16 w-20 flex items-center justify-center block md:hidden">
            <MobileMenu
              menu={menu}
              modalIsOpen={modalIsOpen}
              openModal={openModal}
              closeModal={closeModal}
            />
          </div>
        </div>
        <div className="hidden md:flex flex-1 items-center justify-start">
          <Link href="/#overview">
            <a className={linkClassName}>Overview</a>
          </Link>
          <Link href="/#emcees">
            <a className={linkClassName}>Emcees</a>
          </Link>
          <Link href="/#speakers">
            <a className={linkClassName}>Speakers</a>
          </Link>
          <Link href="/#schedule">
            <a className={linkClassName}>Schedule</a>
          </Link>
          <Link href="/#sponsors">
            <a className={linkClassName}>Sponsors</a>
          </Link>
          <Link href="/jobs">
            <a className={linkClassName}>Jobs</a>
          </Link>
          <Link href="/#organizers">
            <a className={linkClassName}>Organizers</a>
          </Link>
        </div>
        {config.ENABLE_REGISTRATION && (
          <Link href="/register">
            <a className="hidden md:flex w-48 lg:w-64 h-24 bg-blue text-white justify-center items-center text-xl lg:text-2xl cursor-pointer">
              <div className="text-2xl">Register</div>
            </a>
          </Link>
        )}
      </div>
    </div>
  );
}
